import { el, text } from 'redom';
import { dispatch } from './dispatch';
import { AudioPlayer } from './player';
import { Admin } from './admin';
import { StreamList } from './estudios';
import { defaultStream, iframeUrl, logoImages } from './config';

class Iframe {
  constructor (language) {
    this.el = el('iframe', {
      src: iframeUrl[language],
      height: "100%",
      frameborder: "0",
      allowfullscreen: "true",
      //
    })
  }
}

export class App {
  constructor () {
    let language = location.search.slice(1).split('=')[1];
    let logoImage;
    let url;
    if (  language !== undefined ) {
      url = iframeUrl[language.substr(0,2)];
      logoImage = logoImages[language.substr(0,2)];
    }
    if ( url  === undefined ) {
      url = iframeUrl.pt;
      logoImage = logoImages.pt;
      language = 'pt';
    }
    this.el = el('.app',
      el('#header',
        el('.container',
          el('.logo',
            el('img', {src: logoImage})
          ),
          el('.radio-title',
            el('.container',
              el('.frequency', 'FM 89.6 MHz'),
              el('div', 'São Gregório'),
              el('div', 'Caldas da Rainha')
            )
          )
        )
      ),
      el('#player',
        //this.frame = new Frame(),
        el('.player-container',
          this.player = new AudioPlayer(defaultStream),
        )
        //this.streamchooser = new StreamChooser(),
      ),
      el('#frame',
        this.admintoggle = el('a.close', 'A', {
          title: 'ADMIN',
          href: 'javascript:void(0)',
          onclick: (e) => dispatch(this, 'toggleAdmin', true),
          style: 'display: none;'
        }),
        this.frame = new Iframe(language.substr(0,2)),
      ),
      el('#admin.hidden',
        this.admin = new Admin(),
      ),
      //el('.grd-row#estudios',
      //  this.streamlist = new StreamList(),
      //),
      el('#footer',
        el('.container',
          text('powered by OSSO.pt')
        )
      )
    );
  }
  onmount () {
    //dispatch(this, 'checkStreams', true);
    //this.intervalID = window.setInterval(() => {
    //  dispatch(this, 'checkStreams', '');
    //}, 60000);
    this.eventSource = new EventSource("/stream");
    this.eventSource.onmessage = function(e) {
      console.log('EVENT SOURCE EIRA');
      console.log(e.data);
    };
    this.eventSource.addEventListener("status", (e) => {
      console.log('Events data');
      let data = JSON.parse(e.data);
      console.log(data);
      dispatch(this, 'checkStreamsEvent', data);
    })
    this.eventSource.onerror = function(e) {
      console.log('EVENT SOURCE ERROR');
      console.log(e);
      console.log(e.error);
    };


  }

  update (data) {
    let { streams, admin } = data;
    if ( (admin !== undefined ) &&  ( admin !== this.isAdmin ) ){
      this.isAdmin = admin;
      if (this.isAdmin) {
        this.admintoggle.style.display = null;
      } else {
        this.admintoggle.style.display = 'none';
      }
    }
    if ( streams )  {
      //this.streamlist.update(streams);
      if ( this.isAdmin ) {
        this.admin.streamchooser.update(streams);
      }
    }
  }
}
