import { el, text, list, setChildren } from 'redom';
import { dispatch } from './dispatch';


class StreamChooser {
  constructor () {
    this._onchange = (e) => {
      dispatch(this, 'forceStream', e.target.value)
    }

    this.el = el('.choices', { style: 'margin: 1em auto; width: 80%'},
      this.select = el('select', {
          onchange: this._onchange,
          style: 'text-align: center',
        },
      ),
    )
  }
  update (data) {
    let value = this.select.value;

    //this.select.removetEvenListener('change', this._onchange);
    this.select.onchange = null;

    let keep;
    let choiceEls = [el('option', '-- escolhe o canal a escutar --', { value: ''})];
    for (var i = 0, l = data.length; i < l; i++) {
      var v = data[i];
      if ( v.active ) {
        if (v.value === value ) {
          keep = true;
        }
        if ( v.label == 'Osso' ) {
          v.label = 'Eira'
        }
        choiceEls.push(el('option', v.label, { value: v.value }));
      }
    }
    setChildren(this.select, choiceEls);

    this.select.onchange = (e) => dispatch(this, 'forceStream', e.target.value);

    if (keep) {
      this.select.value = value;
    }
  }
}

class ScheduleForm {
  constructor () {
    this.el = el('.form', {style: 'display: none;'},
      this.select = el('select', {
        style: 'margin: 6px; width: 90%'
      }),
      el('label', 'Início', {style: 'display: inline-block; width: 42%; margin: 6px'},
        this.start = el('input', {
          //placeholder: "dd/mm/aaaa HH:MM",
          placeholder: "dd/mm HH:MM",
        }),
      ),
      el('label', 'Fim', {style: 'display: inline-block; width: 42%; margin: 6px'},
        this.end = el('input', {
          //placeholder: "dd/mm/aaaa HH:MM",
          placeholder: "dd/mm HH:MM",
        })
      ),
      el('.center',
        el('.btn--green', 'Guarda', {
          style: 'width: 40%; display: inline-block; margin:6px;',
          onclick: e => dispatch(this, 'saveSchedule', this)
        }),
        el('.btn--red', 'Fecha', {
          style: 'width: 40%; display: inline-block; margin:6px;',
          onclick: e => dispatch(this, 'deleteSchedule', this)
        })
      )
    )
  }
  update(data) {
    this.id = ( data.id ) ? data.id : '';
    this.el.style.display = null;
    this.end.value = ( data.end ) ?  data.end : '';
    this.start.value = ( data.start ) ? data.start : '';
    if (data.title) {
      this.select.value = data.title;
    } else {
      this.select.value = '';
    }
  }
  hide(){
    this.el.style.display = 'none';
  }
  setChoices(streams) {
    let choiceEls = [el('option', '-- escolhe o canal a agendar --', { value: ''})];
    for (var i = 1, l = streams.length; i < l; i++) {
      var v = streams[i];
      choiceEls.push(el('option', v.label, { value: v.label }));
    }
    setChildren(this.select, choiceEls);
  }
  values() {
    return {
      title: this.select.value,
      start: this.start.value,
      end: this.end.value,
      id: this.id,
    };
  }
}

class ScheduleItem {
  constructor () {
    this.data = {};
    this.el = el('tr',
      this.name = el('td'),
      this.start = el('td'),
      this.end = el('td'),
      this.edit = el('td', {
        style: 'text-align: center;',
        onclick: e => dispatch(this, 'editSchedule', this),
      }),
    )
  }
  update (data) {
    this.data = data;
    //var options = {
    //  year: 'numeric', month: 'numeric', day: 'numeric',
    //  hour: 'numeric', minute:'numeric'
    //};
    var options = {
      month: 'numeric', day: 'numeric',
      hour: 'numeric', minute:'numeric'
    };
    this.name.textContent = data.title;
    this.start.textContent = new Intl.DateTimeFormat('pt-PT', options).format(new Date(data.start));
    this.end.textContent = new Intl.DateTimeFormat('pt-PT', options).format(new Date(data.end));
    this.edit.textContent = 'O';
    this.data.start = this.start.textContent;
    this.data.end = this.end.textContent;
  }
  values() {
    return this.data;
  }
}

class Schedule {
  constructor () {
    this.el = list('tbody', ScheduleItem)
  }
  update (data) {
    //console.log(data);
    this.el.update(data);
  }
}

export class Admin {
  constructor () {
    this.el = el('.admin',
      el('a.close', 'X', {
        //href: '/admin/logout',
        title: 'LOGOUT',
        href: 'javascript:void(0)',
        onclick: (e) => dispatch(this, 'toggleAdmin', false),
      }),
      el('a.close', '0', {
        href: 'javascript:void(0)',
        onclick: (e) => dispatch(this, 'forceStream', ''),
        style: 'right: initial; left: 6px;',
        title: 'Limpa o ONAIR',
      }),
      this.streamchooser = new StreamChooser(),
      el('.label-wrapper',
        el('label', 'Mensagem: ', {style: 'display: block; width: 100%; margin: 6px'},
          this.message = el('input', {
            placeholder: "mensagem",
            onchange: (e) => dispatch(this, 'setMessage', this.message.value),
            style: 'display: inline; width: initial; margin-left: 2em;',
          })
        ),
      ),
      el('.btn.btn--blue', 'Novo programa', {
        onclick: e => dispatch(this, 'editSchedule', null),
      }),
      this.form = new ScheduleForm(),
      el('table', {style: 'width: 100%' },
        el('thead',
          el('tr',
            el('th', 'Nome'),
            el('th', 'Início'),
            el('th', 'Fim'),
            el('th', 'Editar'),
          )
        ),
        this.schedule = new Schedule(),
      )
    )
  }
  update(data) {
    //console.log('update schedule');
    //console.log(data)
    this.schedule.update(data);
    if ( data.length === 0 ) {
      this.el.getElementsByTagName('table')[0].style.display = 'none';
    } else {
      this.el.getElementsByTagName('table')[0].style.display = null;
    }
  }
}

