import { el, list, text } from 'redom';
import { dispatch } from './dispatch';
import { defaultStream, defaultEncoding } from './config';

/*
 * AudioPlayer
 * LedLight
 * LedBox
 */

class ProgressBar {
  constructor () {
    this.el = el('.progress-bar',
      el('img', {
        src: 'images/waveform.png'
      }),
      el('.progress-container',
        this.progress = el('.progress')
      )
    );
  }
  onmount() {
    this.update();
  }
  update(data) {
    // get schedule
    data = {
      start: 0,
      end: 100,
      name: 'Catarina Botelho',
      next: {
        start: 120,
        end: 220,
        name: 'Francisca Aires Mateus'
      }
    }
    //let current = 80;
    //let percent = ( ( data.end - current) / (data.end - data.start) * 100) + '%';
    //console.log('PERCENT: ' + percent);
    //this.progress.style = 'width: ' + percent;
  }
}

export class NowPlaying {
  constructor () {
    this.el = el('.playing',
      el('.now-playing',
        text('on air - '),
        this.nowplaying = text('')
      ),
      this.progress = new ProgressBar(),
      //el('.next',
      //  text('next - '),
      //  this.next = text('')
      //),
    )
  }
  update(data) {
    let nowplaying = ( data.message ) ? data.message : data.nowplaying;
    //console.log("Updating NOWPLAYNG");
    //console.log("data.metadata = '" + data.metadata + "'");
    //console.log("data.message = '" + data.message + "'");
    //console.log("data.nowplaing = '" + data.nowplaying + "'");
    if ( (data.metadata) && (! data.message))  {
      nowplaying = data.metadata;
    }
    //console.log("nowplaying = '" + nowplaying + "'");

    this.nowplaying.textContent = nowplaying;
  }
}

export class AudioPlayer {
  constructor(srcUrl) {
    this.activeStream = false;
    this.playing = false;
    this.defaultUrl = (srcUrl === undefined) ? defaultStream : srcUrl;
    let timestamp = +new Date();
    this.hide = (className) => {
      const show = (className == 'play') ? 'stop' : 'play';
      let hideEls = this.el.getElementsByClassName(className);
      let showEls = this.el.getElementsByClassName(show)
      for (var i = 0, l = hideEls.length; i < l; i++) {
        var v = hideEls[i];
        v.style.display = 'none';
      }
      for (var i = 0, l = showEls.length; i < l; i++) {
        var v = showEls[i];
        v.style.display = null;
      }
    }
    this.errorHandler = (e) => {
      console.log(e);
      this.hide('stop');
    }


    this.el = el('.player',
      el('.audio-container',
        el('.audio',
          this.audio = el('audio', {
              onended: (e) => {
                // should not happend stream is always available if have internet
                // FIXME: try to reconnect?????
                this.hide('stop');
                if (this.activeStream) {
                  this.activeStream = false;
                }
              },
              onpause: (e) => {
                this.hide('stop');
                if (this.activeStream) {
                  this.activeStream = false;
                }
              },
              onplay: (e) => {
                this.hide('play');
              },
              onerror: (e) => {
                this.hide('stop');
              }
            },
            this.source = el('source', {
              src: '',
              //src: this.defaultUrl + '?t=' + timestamp, type: "audio/" + defaultEncoding,
              onerror: (e) => {
                // connection error
                // FIXME: try to reconnect?????
                this.hide('stop');
              },
            })
          ),
          el('.sPlay.play', {
            onclick: e => {
              this.playing = true;
              this._play();
            }
          }),
          el('.sStop.stop', {
            onclick: e => {
              this.playing = false;
              this._stop();
            },
            style: 'display: none;',
          }),
        ),
      ),
      this.nowplaying = new NowPlaying(),
    )
  }
  update(data){
    if (data) {
      this._play(data);
    }
  }
  _play(src) {
    if ( ! src ) {
      src = this.defaultUrl;
    };
    let typ = src.substr(src.length -3);
    if ( ( typ != 'mp3') && ( typ != 'ogg') ) {
      return;
    }
    this.source.type = 'audio/' + typ;
    let timestamp = +new Date();
    this.source.src = src + '?n=' + timestamp;
    this.audio.load();
    this.audio.play();
  }
  _stop() {
    this.audio.pause();
  }
  play(stream) {
    if ( stream ) {
      this._play(stream.value);
      return;
    }
    if (this.playing) {
      this._play();
      return;
    }
    this._stop();
  }
}
