import { defaultEncoding, oggStreams, rootUrl } from './config';
export function compareDates (a , b) {
  const timeA = a.time;
  const timeB = b.time;

  let comparison = 0;
  if (timeA > timeB) {
    comparison = 1;
  } else if (timeA < timeB) {
    comparison = -1;
  }
  return comparison;

}

export class CountDownTimer {
  constructor(duration, granularity) {
    this.granularity = granularity || 1000;
    this.duration = duration;
    this.tickFtns = [];
    this.endFtns = [];
    this.running = false;
  }
  start() {
    if (this.running) {
      return;
    }
    this.running = true;
    var start = Date.now(),
        that = this,
        diff, obj;

    (function timer() {
      if ( that.stopped ) {
        return;
      }
      diff = that.duration - (((Date.now() - start) / 1000) | 0);

      if (diff > 0) {
        that.timeout = setTimeout(timer, that.granularity);
      } else {
        diff = 0;
        that.endFtns.forEach(function(ftn) {
          ftn.call(this);
        }, that);
      }

      obj = that.parse(diff);
      that.tickFtns.forEach(function(ftn) {
        ftn.call(this, obj.minutes, obj.seconds);
      }, that);
    }());
  }
  onTick(ftn) {
    if (typeof ftn === 'function') {
      this.tickFtns.push(ftn);
    }
  }

  onEnd(ftn) {
    if (typeof ftn === 'function') {
      this.endFtns.push(ftn);
    }
  }

  expired() {
    return !this.running;
  }

  stop() {
    if (this.expired()) {
      return;
    }
    this.stopped = true;
  }

  parse(seconds) {
    return {
      'minutes': (seconds / 60) | 0,
      'seconds': (seconds % 60) | 0
    };
  }
}

// Slugify a string
// url:https://lucidar.me/en/web-dev/how-to-slugify-a-string-in-javascript/
export function slugify(str)
{
    str = str.replace(/^\s+|\s+$/g, '');

    // Make the string lowercase
    str = str.toLowerCase();

    // Remove accents, swap ñ for n, etc
    var from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
    var to   = "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    // Remove invalid chars
    str = str.replace(/[^a-z0-9 -]/g, '')
    // Collapse whitespace and replace by -
    .replace(/\s+/g, '-')
    // Collapse dashes
    .replace(/-+/g, '-');

    return str;
}


//export const rootUrl = 'https://radio.osso.pt';
//export const rootUrl = '';

export const parseStreams = (txt) => {
  return txt.split('\n').filter((line) => {
    if ( line != '' ) {
      return true;
    }
    return false;
  }).map( (stream) => {
      let eslug = slugify(stream);
      let mountpoint;
      if ( oggStreams.indexOf(eslug) !== -1 ) {
        mountpoint = rootUrl + '/' + eslug + '.' + 'ogg';
      } else {
        mountpoint = rootUrl + '/' + eslug + '.' + defaultEncoding;
      }
      return { value: mountpoint, label: stream, active: false};
  });
}
