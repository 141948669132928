import { el, list, text } from 'redom';
import { dispatch } from './dispatch';

const formatText = (t) => {
  let value = t;
  if (t.split(' ').length > 2 ) {
    let splitted = t.split(' ');
    value = splitted[0];
    for (var i = 1, l = splitted.length; i < l - 1; i++) {
      var v = splitted[i];
      value += ' ' + v.slice(0,1) + '.';
    }
    value += ' ' + splitted[splitted.length - 1]
    //value = splitted[0] + ' ' + splitted[1].slice(0,1) + '. ' + splitted[2];
  }
  return value;
}

export class StreamListItem {

  constructor () {
    this.playing = false;
    this.active = false;
    this.el = el('.stream.grd-row-col-3-6--sm',
      this.button = el('span.empty', {
        onclick: () => this.play(),
      }),
      this.led = el('span.activo'),
      this.name = el('span.name'),
    )
  }
  update (data, index, allData, parent) {
    //console.log('update ESTUDIOS_ITEM');
    this.stream = data;
    this.parent = parent;
    this.name.textContent = formatText(data.label);
    if (data.active) {
      this.active = true;
      this.el.classList.add('active');
      if ( parent.playing == data.label ) {
        this.button.className = "pause";
        this.playing = true;
      } else {
        this.button.className = "play";
        this.playing = false;
      }
      //if ( this.playing ) {
      //  this.button.className = "pause";
      //} else {
      //  this.button.className = "play";
      //}
    } else {
      this.active = false;
      this.el.classList.remove('active');
      this.button.className = "play";
    }
  }
  play() {
    //console.log(this.stream);
    if (! this.active ) {
      this.button.className = "play";
      return;
    }
    if (this.playing) {
      this.parent.playing = '';
      dispatch(this, 'playStream', '');
    } else {
      dispatch(this, 'playStream', this.stream);
      this.parent.playing = this.stream.label;
    }
    this.parent.play()

  }
}

export class StreamList {
  constructor () {
    this.playing = '';
    this.el = el('.stream-list',
      el('.header.grd-row',
        this.header = text('OPEN STUDIOS'),
      ),
      this.list = list('.streams.grd-row', StreamListItem),
      /*
      el('.footer.grd-row',
        this.footer = text('Pressione PLAY para ouvir os residentes ACTIVOS no seu estúdio'),
      )
      */
    )
    //dispatch(this, 'getStreams', '')
  }
  update(data) {
    //console.log('update streams');
    //console.log(data);
    this.list.update(data.slice(1), this);
    //this.list.update(data, this);
  }
  play() {
    for (var i = 0, l = this.list.views.length; i < l; i++) {
      var v = this.list.views[i];
      if ( ( v.stream.label === this.playing ) ) {
        v.playing = true;
        v.button.className = "pause";
      } else {
        v.playing = false;
        v.button.className = "play";
      }
    }
  }
}
