const config = {
  //defaultStream: 'https://stream.osso.pt/eira.mp3',
  defaultStream: '/eira.mp3',
  defaultEncoding: 'mp3',
  rootUrl: '',
  //apiUrl: 'https://stream.osso.pt',
  apiUrl: '',
  streamUrl: '',
  iframeUrl: {
    en: 'https://arquivo.osso.pt/en/um-player-en',
    pt: 'https://arquivo.osso.pt/um-player/'
  },
  oggStreams: ['francisca-aires-mateus',],
  logoImage: "images/logo_eira_radio_platform.png",
  logoImages: {
    en: "images/logo_eira_radio_platform_en.png",
    pt: "images/logo_eira_radio_platform_pt.png",
  }
}


export const defaultStream = config.defaultStream;
export const defaultEncoding = config.defaultEncoding;
export const rootUrl = config.rootUrl;
export const apiUrl = config.apiUrl;
export const streamUrl = config.streamUrl;
export const iframeUrl = config.iframeUrl;
export const oggStreams = config.oggStreams;
export const logoImage = config.logoImage;
export const logoImages = config.logoImages;
